import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import GoogleTagManager, { GoogleTagManagerId } from '../parts/commons/GoogleTagManager'
import { googleTagManagerId } from '../../services/AnalyticsService'

export const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    document.body.classList?.remove('loading')
  }, [])

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
        />
        <meta
          name="description"
          content="Bringing the arcade experience back with a GameFi arcade universe where anyone can play-and-earn for free anytime, anywhere. PLAY-CRAFT-OWN-EARN in Akiverse!"
        />
        <meta property="og:title" content="AKIVERSE" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://akiverse.io/" />
        <meta property="og:image" content="https://akiverse.io/assets/img/OGP.png" />
        <meta
          property="og:description"
          content="Bringing the arcade experience back with a GameFi arcade universe where anyone can play-and-earn for free anytime, anywhere. PLAY-CRAFT-OWN-EARN in Akiverse!"
        />
        <link rel="stylesheet" type="text/css" href="/css/common.css" />
        <link rel="stylesheet" type="text/css" href="/css/slick.css" />
        <link rel="stylesheet" type="text/css" href="/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/css/globals.css" />
        <link rel="stylesheet" type="text/css" href="/css/loading.css" />
      </Head>

      <GoogleTagManager googleTagManagerId={googleTagManagerId as GoogleTagManagerId} />

      <main>
        <div>
          <RecoilRoot>
            <Component {...pageProps} />
          </RecoilRoot>
        </div>
      </main>
      <Script src="/js/jquery-3.4.1.min.js" strategy="beforeInteractive"></Script>
      <Script src="/js/jquery.cookie.min.js" strategy="beforeInteractive"></Script>
      <Script src="/js/iscroll.min.js" strategy="beforeInteractive"></Script>
      <Script src="/js/slick.min.js" strategy="beforeInteractive"></Script>
      <Script src="/js/scripts.js" strategy="afterInteractive"></Script>
    </>
  )
}
