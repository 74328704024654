export const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''

export const AnalyticsService = {
  setUserId: (userId: string) => {
    window.dataLayer.push({ user_id: userId })
  },
}

declare global {
  interface Window {
    dataLayer: Record<string, unknown>[]
  }
}
